<template>
  <div class="d-flex">
    <!-- Show the thumbnail -->
    <div>
      <v-img
        :src="data.post_preview.thumbnail"
        max-height="240"
        max-width="240"
        class="rounded-lg"
        cover
      />
    </div>

    <!-- Show the post body -->
    <div class="flex-grow-1">
      <!-- Show the link button -->
      <div class="d-flex justify-end">
        <v-btn
          text
          color="primary"
          @click="redirectToPost"
        >
          <v-icon left>
            open_in_new
          </v-icon>

          View Post
        </v-btn>
      </div>

      <!-- Show the caption -->
      <p class="text-body-2 pl-6 mt-6">
        {{ (data.post_preview.caption || "").slice(0, 695) }} {{ data.post_preview.caption.length > 695 ? "..." : "" }}
      </p>

      <!-- Show the post stats -->
      <div class="d-flex pl-6 mt-6">
        <div class="d-flex mr-6" v-if="data.post_preview.likes">
          <v-icon color="text">thumb_up</v-icon> &nbsp;
          {{ nFormatter(data.post_preview.likes) }}
        </div>

        <div class="d-flex mr-6" v-if="data.post_preview.comments">
          <v-icon color="text">comment</v-icon> &nbsp;
          {{ nFormatter(data.post_preview.comments) }}
        </div>

        <div class="d-flex mr-6" v-if="data.post_preview.views">
          <v-icon color="text">play_circle</v-icon> &nbsp;
          {{ nFormatter(data.post_preview.views) }}
        </div>

        <div class="d-flex" v-if="engagementRate">
          <v-icon color="text">leaderboard</v-icon> &nbsp;
          {{ engagementRate }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "Post",

  // Accept incoming data
  props: {
    // The post data
    data: {
      type: Object,
      required: true
    }
  },

  // Define local computable properties
  computed: {
    /**
     * Calculate the engagement rate
     *
     * @returns {number|null}
     */
    engagementRate() {
      // If no profile preview
      if (!this.data.influencer_preview || !this.data.influencer_preview.followers) {
        return null
      }

      // Otherwise, calculate the engagement rate
      const engagements = (this.data.post_preview.likes || 0) + (this.data.post_preview.comments || 0)
      const followers = this.data.influencer_preview.followers

      return ((engagements / followers) * 100).toFixed(2)
    }
  },

  // Define local method functions
  methods: {
    /**
     * Redirect to the post
     *
     * @returns {void}
     */
    redirectToPost() {
      window.open(this.data.post_preview.url, "_blank")
    }
  }
}
</script>
